// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useEffect, useState } from 'react';
import { Drawer } from '@plesk/ui-library';
import { Observer, redirect, getComponent, Task, escapeHtml } from 'jsw';
import PropTypes from 'prop-types';
import useEventListener from '../../../common/hooks/useEventListener';
import { addToast } from '../../helpers/toasts';
import { isMatch } from './Link';

const STATUS_RUNNING = 'running';
const getTaskProgressBar = () => getComponent('asyncProgressBarWrapper');

// Workaround for lists that do not support reload
const sameUrls = (redirectUrl, pageUrl) => {
    if (redirectUrl === pageUrl) {
        return true;
    }

    try {
        const params = new URLSearchParams(new URL(redirectUrl, window.location.origin).search);
        return params.get('returnUrl') === pageUrl || params.get('pageUrl') === pageUrl;
    } catch {}

    return true;
};

const DrawerWithProgress = ({
    children,
    dataType,
    isOpen,
    onClose,
    task,
    navigate,
    pageUrl,
    ...props
}) => {
    const [steps, setSteps] = useState([]);
    const [title, setTitle] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);
    const [status, setStatus] = useState(null);
    const taskId = task ? task.id : null;

    const isClientSideRedirect = () => navigate && redirectUrl && isMatch(redirectUrl);

    const handleTaskUpdate = ({ id, steps, status: taskStatus, errors, output, progressValue, progressTitle, progressTitleHtml }) => {
        if (!isOpen || taskId !== id) {
            return;
        }

        const title = progressTitleHtml || escapeHtml(progressTitle);
        const status = Task.STATUS_STARTED === taskStatus ? STATUS_RUNNING : taskStatus;
        const messages = messages => (messages || []).map(message => (<div key={message}>{message}</div>));

        setStatus(status);
        if (steps && steps.length) {
            setTitle(title);
            setSteps(steps.map(({ errors, ...props }) => ({ children: messages(errors), ...props })));
        } else {
            setSteps([{
                title,
                status,
                progress: Number(progressValue),
                children: (
                    <>
                        {messages(errors)}
                        {messages(output)}
                    </>
                ),
            }]);
        }
    };

    const handleTaskComplete = ({ id, redirect: redirectUrl, status }) => {
        if (!isOpen || taskId !== id) {
            return;
        }

        if (Task.STATUS_DONE === status) {
            setRedirectUrl(redirectUrl?.url || redirectUrl);
        }
    };

    useEffect(() => {
        const taskProgressBar = getTaskProgressBar();
        const onOpen = () => {
            taskProgressBar?.hide();
            setSteps([]);
            setTitle(null);
            setRedirectUrl(null);
            setStatus(null);
        };

        const onClose = async () => {
            if (!isInProgress()) {
                await deleteTask();
            }
            taskProgressBar?.show();
        };

        isOpen ? onOpen() : onClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        if (taskId) {
            getTaskProgressBar()?.update();
            handleTaskUpdate(task);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId, task]);

    useEventListener('plesk:taskStepUpdate', handleTaskUpdate, Observer);
    useEventListener('plesk:taskComplete', handleTaskComplete, Observer);

    const isInProgress = () => Task.STATUS_NOT_STARTED === status || STATUS_RUNNING === status;

    const handleClose = () => {
        if (!redirectUrl) {
            onClose();
            return;
        }

        const clientRedirectSameUrls = isClientSideRedirect() && sameUrls(redirectUrl, pageUrl);
        const clientRedirectNotSameUrls = isClientSideRedirect() && !sameUrls(redirectUrl, pageUrl);

        if (clientRedirectSameUrls) {
            navigate(redirectUrl, { state: { reload: true }, replace: true });
            onClose();
            return;
        }

        if (clientRedirectNotSameUrls) {
            navigate(redirectUrl, { state: { reload: true } });
            return;
        }

        redirect(redirectUrl);
    };

    const deleteTask = () => taskId && getTaskProgressBar()?.getItems().find(item => item.getId() === taskId)
        ?.remove();

    const onFinish = () => {
        deleteTask();
        addToast({
            intent: 'success',
            // eslint-disable-next-line react/no-danger
            message: <span dangerouslySetInnerHTML={{ __html: title || steps[0].title }} />,
        });
    };

    return (
        <Drawer
            data-type={dataType}
            isOpen={isOpen}
            onClose={handleClose}
            progress={steps.length ? {
                cancelable: false,
                // eslint-disable-next-line react/no-danger
                title: <span dangerouslySetInnerHTML={{ __html: title }} />,
                steps,
                onFinish,
                autoCloseTimeout: 1,
            } : null}
            hideButton={isInProgress()}
            {...props}
        >
            {children}
        </Drawer>
    );
};

DrawerWithProgress.propTypes = {
    children: PropTypes.node,
    dataType: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    task: PropTypes.shape({
        id: PropTypes.string.isRequired,
        steps: PropTypes.array,
        status: PropTypes.string,
        progressTitle: PropTypes.string,
        progressTitleHtml: PropTypes.string,
    }),
    navigate: PropTypes.func,
    pageUrl: PropTypes.string,
};

DrawerWithProgress.defaultProps = {
    children: null,
    isOpen: false,
    task: null,
    navigate: null,
    pageUrl: null,
};

export default DrawerWithProgress;
